/* eslint-disable no-console, max-lines */
import React, { createContext, useEffect, useReducer, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import ChatApi from './api/chat-api';
import Utils from './utils/chat-utils';
import HistoryMessage from './messages/HistoryMessage';
const ChatContext = createContext('chat');

const ChatProvider = ({ ally, botName, token, infConversation, children }) => {
  const { config } = children.props;
  const browserName = Utils.detectBrowser();
  const [defaultConversationId, setDefaultConversationId] = useState('');
  const [userIP, setUserIP] = useState(Utils.getRandomIP());
  const [chatData, dispatchChatData] = useReducer(Utils.chatDataReducer, { messages: [], config });
  const clientId = `${userIP}-${browserName}`;
  const { channel, advisor, integrationId, conversation, campaignId } = infConversation;

  const detectUserIP = async () => {
    try {
      const ipRequest = await fetch('https://api.ipify.org/?format=json');
      const ipData = await ipRequest.json();
      if (ipData) setUserIP(ipData.ip);
    } catch (e) { console.error(e); }
  };

  const deployHistoryMessages = async () => {
    const urlRegex = /https?:\/\/\S+/;
    const regexFile = /[\w\s()-]+\.\w+[a-z A-Z 0-9]/gm;
    const arrayMessages = await HistoryMessage.getHistoryMessage(infConversation, ally, channel);
      if (arrayMessages && arrayMessages.length > 0) {
        arrayMessages.forEach((message) => {
            const { details = '' } = message;
            const { _seconds: seconds } = details.timestamp;
            const link = details.message.match(urlRegex);
            const desc = details.message.split(urlRegex);
            const pathFile = details.message.match(regexFile);
            const origin = details.fromClient ? 1 : 0;
            if (pathFile) {
              const extName = (pathFile[0] || '').split('.');
              dispatchChatData({
                type: 'new',
                payload: [{
                  type: Utils.extensionFile(extName[1].toLowerCase()) || 'text',
                  audio: details.message || '',
                  audioURL: details.message || '',
                  text: details.message,
                  from: origin,
                  date: seconds,
                  description: pathFile,
                }],
              });
            } else if (link) {
              dispatchChatData({
                type: 'new',
                payload: [{
                  type: 'image-history',
                  source: link,
                  from: origin,
                  date: seconds,
                  description: desc,
                }],
              });
            } else {
              dispatchChatData({
                type: 'new',
                payload: [{
                  type: 'history',
                  text: details.message,
                  from: origin,
                  date: seconds,
                }],
              });
            }
        });
      }
  };

  useEffect(() => {
    if (!userIP.match(/[0-9][-r]+/)) webSocketSession();
    // eslint-disable-next-line
  }, [userIP]);

  const webSocketSession = () => {
    const socket = io(`${new URL(process.env.REACT_APP_LIST_DETAIL_WEBSOCKET)}`, {
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      console.log('Websocket has been Connected');
    });
    socket.on(`${ally}/${integrationId}/${userIP}-${browserName}-${botName}`, function (data) {
      const { messageText, responseType, fileName } = data;
      dispatchChatData({
        type: 'add',
        payload: [{
          type: responseType || 'text',
          text: messageText,
          from: 1,
          description: fileName || '',
          date: Math.round(Date.now() / 1000),
        }],
      });
    });

    socket.on('killLibraryConnection', () => {
      console.log('Kill session');
      socket.emit('KillSession', { message: 'Kill Session' });
    });
    socket.on(`${ally}/${integrationId}/${userIP}-${browserName}-${botName}_reconnect`, (data) => {
      socket.emit('room', { clientId, campaign: campaignId, priority: true, ...data });
    });
  };

  useMemo(() => {
    deployHistoryMessages().then(() => {
      detectUserIP()
        .then(() => {
          setDefaultConversationId(Utils.setupDefaultConversationId(clientId, botName));
        })
        .catch(() => {
          setDefaultConversationId(Utils.setupDefaultConversationId(clientId, botName));
        });
    });
  }, []);

  useEffect(() => {
    if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}/.test(clientId)) {
      if (!infConversation.advisor) {
        const { advisor, provider = '', dialogId = '', sendText } = infConversation;
        if (!advisor && sendText) {
          dispatchChatData({
            type: 'add',
            payload: [{
              type: 'text',
              text: '¡Hola!, tu ejecutiva comercial estará pronto para ayudarte.',
              from: 1,
              date: Math.round(Date.now() / 1000),
            }],
          });
          internalSendMessage({ provider, dialogId, text: sendText.text });
        }
      }
    }
  }, [defaultConversationId]);

  useEffect(() => {
    if (conversation.length > 0) addLastMessage();
  }, [conversation]);

  const addLastMessage = async () => {
    const lastMessages = [];
    const arrayMessages = await HistoryMessage.getHistoryMessage(infConversation, ally, channel);
      if (arrayMessages && arrayMessages.length > 0) {
        arrayMessages.forEach((message) => {
            const { details } = message;
            const origin = details.fromClient ? 1 : 0;
            if (origin === 1) lastMessages.unshift(message);
        });
      }
      if (lastMessages[0].details.fromClient) {
          dispatchChatData({
            type: 'add',
            payload: [{
              type: conversation[0].responseType,
              text: conversation[0].message,
              from: 1,
              description: conversation[0].fileName,
              date: conversation[0].date,
            }],
          });
      }
    };
    const onEndpointResponse = (response) => {
      // noinspection JSUnresolvedVariable
      const responseText = response.data
        ? response.data.message || response.data.responses
        || response.data.fulfillmentText || response.data.fulfillmentMessages || response.data
        : response.message || response.error || '…';
      const responses = Utils.typeMessages(responseText, 1000);
      dispatchChatData({
        type: 'new',
        payload: responses.map((it) => {
          let { text } = it;
          if (text && Object.keys(text).includes('text')) {
            const { text: textArray = [] } = text;
            try {
              if (textArray.length > 1) text = textArray.join('\n');
              else text = textArray[0] || '';
            } catch (e) { console.error(e); }
          }
          return {
            ...it,
            text: text ? text.toString().trim() : undefined,
            date: Math.round(Date.now() / 1000),
          };
        }).filter((it) => {
          const isMessage = it.type === 'text';
          const validMessage = isMessage ? (it.text && it.text.length > 0) : true;
          return it.type !== 'pause' ? validMessage : true;
        }),
      });
      if (!response.success) console.error(response);
    };
    const onEndpointError = (error) => {
      try {
        const errorMessage = error.data.error || error.data.message || error.message || '';
        dispatchChatData({
          type: 'add',
          payload: [{
            type: 'text',
            text: errorMessage,
            from: 1,
            date: Math.round(Date.now() / 1000),
            error: 1,
          }],
        });
      } catch (e) { console.error(e); }
    };
    const internalSendMessage = ({
      provider,
      dialogId,
      text,
      typeFile,
      description,
    }) => {
      if (advisor && provider === 'watson') {
        const { dataPerson = {} } = infConversation;
        ChatApi.sendMessageWatsonAdvisor({
          campaignId,
          messageText: text,
          clientId: infConversation.clientId,
          adviserEmail: dataPerson.adviserEmail,
          clientCampaignId: dataPerson.clientCampaignID,
          ally,
          channel,
          integrationId,
          advisor,
          responseType: typeFile || 'text',
          fileName: description || '',
        })
          .then(infConversation.advisor ? '' : onEndpointResponse)
          .catch(onEndpointError);
      } else {
        switch (provider || '') {
          case 'dialogflow': {
            const { conversationId, conversationStart } = chatData;
            if (!conversationId || conversationId.length <= 0) {
              ChatApi.initDialogFlow(token)
                .then(async (response) => {
                  if (response.success) {
                    const { conversationId: newConvoId, conversationStart: newConvoStart }
                      = response.data;
                    await ChatApi.sendMessageDialogFlow({
                      dialogId,
                      message: text,
                      conversationId: newConvoId,
                      conversationStart: newConvoStart,
                      clientId,
                      ally,
                      botName,
                      token,
                    }).then(onEndpointResponse)
                      .catch(onEndpointError);
                    dispatchChatData({
                      type: 'convo', conversationId: newConvoId, conversationStart: newConvoStart,
                    });
                  } else {
                    onEndpointError(response.error || 'Unexpected error');
                  }
                })
                .catch(onEndpointError);
            } else {
              ChatApi.sendMessageDialogFlow({
                dialogId,
                message: text,
                conversationId,
                conversationStart,
                clientId,
                ally,
                botName,
                token,
              }).then(onEndpointResponse)
                .catch(onEndpointError);
            }
            break;
          }
          case 'lex':
            ChatApi.sendMessageLex({
              dialogId,
              message: text,
              clientId,
              ally,
              botName,
              token,
              conversationId: defaultConversationId,
            }).then(onEndpointResponse)
              .catch(onEndpointError);
            break;
          case 'watson':
            ChatApi.sendMessageWatson({
              dialogId,
              message: text,
              clientId,
              ally,
              botName,
              token,
              integrationId,
              conversationId: defaultConversationId,
              responseType: typeFile || 'text',
              fileName: description || '',
              name: infConversation.clientName,
            }).then(onEndpointResponse)
              .catch(onEndpointError);
            break;
          default: {
            const errorText = `Unsupported provider: ${provider}`;
            onEndpointResponse({ success: true, data: { message: errorText } });
            break;
          }
        }
      }
    };
    const contextValue = { chatData, dispatchChatData, internalSendMessage, userIP };
    return (<ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>);
  };
  ChatProvider.propTypes = {
    ally: PropTypes.string.isRequired,
    botName: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    infConversation: PropTypes.object,
    children: PropTypes.oneOfType(
      [PropTypes.object, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  };
  export { ChatContext };
  export default ChatProvider;
