import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../chat-provider';
import { List, SimpleListItem } from '@rmwc/list';
import '@rmwc/list/styles';

const ButtonsList = ({ options = {}, title = '' }) => {
  const { chatData: data, dispatchChatData, internalSendMessage } = useContext(ChatContext);

  const selectTitle = () => {
    let newTitle = '';
    if (/menu/.test(title)) {
      const arrayTitle = title.split('>');
      // eslint-disable-next-line
      newTitle = arrayTitle[1];
    } else {
      newTitle = title;
    }
    return newTitle;
  };

  const handleClick = async (buttonText, postback) => {
    const now = Math.round(Date.now() / 1000);
    dispatchChatData({
      type: 'add',
      payload: [{
        type: 'text',
        text: buttonText,
        from: 0,
        date: now,
      }],
    });
    await internalSendMessage({
      dialogId: data.config.dialogId,
      provider: data.config.provider,
      text: postback,
    });
  };

  return (<>
    { selectTitle() }
    <List twoLine>
      {(options || []).filter((it) => (it.value.input.text || '').length > 0)
        .map((it, i) => {
          const { label: buttonText, value } = it;
          const { text: postback } = value.input;
          return (
            <div key={i}>
              <SimpleListItem
                text={buttonText}
                className={'button-list size-buttons-list'}
                onClick={() => {
                  handleClick(buttonText, postback);
              }}>
              </SimpleListItem>
            </div>);
          })}
    </List>
  </>);
};

ButtonsList.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
  };

  export default ButtonsList;
