/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';
import '@rmwc/dialog/styles';

const ImageView = ({ source, title, description, date, file, firebase, storage }) => {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getURLFileOfFireStore(source);
  }, [source]);

  const getURLFileOfFireStore = (source) => {
    if (source.length > 0) {
      setIsLoading(true);
      firebase.ref(source).getDownloadURL()
          .then((resp) => {
            setIsLoading(false);
            setLink(resp);
          })
          .catch((e) => {
            setIsLoading(false);
            // eslint-disable-next-line no-console
            console.log((e.message || e.error));
          });
      } else {
        setLink(storage);
      }
  };

  return (<>
    {!isLoading
    ? file
        ? <div className={'file-style'}>
          <span className={'mdi mdi-24px mdi-file-document'} style={{ color: '#474749' }}/>
          <p>{description}</p>
          <a href={link} download target={'_blank'} style={{ color: '#474749' }}>
            <span className={'mdi mdi-24px mdi-download'} />
          </a>
        </div>
        : <div>
          <img src={`${link}`} style={{ cursor: 'pointer' }}
               width={400}
               height={400}
               alt={title} onClick={() => setOpen(true)} />
          <strong> {title} </strong>
          <p>{description}</p>
          <small>{date}</small>
        </div>
    : <CircularProgress size={'xlarge'} style={{ color: '#4dab10' }} />
    }

    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      renderToPortal={true}
      className={'image-dialog'}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <a href={link} download target={'_blank'} style={{ margin: '10px' }} >
          <span className={'mdi mdi-24px mdi-download'}/>
        </a>
        <Button onClick={() => setOpen(false)} className={'btn-close'}>
          <span className={'mdi mdi-24px mdi-close'} />
        </Button>
      </div>
      <DialogContent style={{ width: '600px', height: '600px' }}>
        <img src={link} alt={title} />
      </DialogContent>
    </Dialog>
  </>);
};

ImageView.propTypes = {
  source: PropTypes.string,
  storage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  file: PropTypes.bool,
  firebase: PropTypes.object,
};

export default ImageView;
