import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';

const AudioView = ({ source, firebase }) => {
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getURLFileOfFireStore(source);
  }, [source]);

  const getURLFileOfFireStore = (source) => {
    setIsLoading(true);
      firebase.ref(source).getDownloadURL()
        .then((resp) => {
          setIsLoading(false);
          setLink(resp);
        })
        .catch((e) => {
          setIsLoading(false);
          // eslint-disable-next-line no-console
          console.log((e.message || e.error));
        });
  };

 return (
   <Fragment>
     {!isLoading
     ? <audio src={link} controls controlsList={'nofullscreen nodownload noremoteplayback'} />
      : <CircularProgress size={'xlarge'} style={{ color: '#4dab10' }} />}
   </Fragment>
 );
};

AudioView.propTypes = {
    source: PropTypes.string,
    description: PropTypes.string,
    firebase: PropTypes.object,
  };

export default AudioView;

