import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@rmwc/tooltip';
import './title-bar.css';

const TitleBar = ({ showTyping = false, titleBarLogo = null, titleBarContent = null }) => (<Tooltip
  content={'Escribiendo…'} align={'bottom'} open={showTyping}
  enterDelay={0} leaveDelay={100}>
  <div className={'titlebar'}>
    {(titleBarLogo && !titleBarContent
      && (<img alt={'BPSmart Logo'} src={titleBarLogo} className={'logo'}/>))}
    {titleBarContent}
  </div>
</Tooltip>);

TitleBar.propTypes = {
  showTyping: PropTypes.bool,
  titleBarLogo: PropTypes.any,
  titleBarContent: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};

export default TitleBar;
