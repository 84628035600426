import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../chat-provider';
import { Card, CardMedia, CardActionButtons, CardActionButton, CardActions } from '@rmwc/card';
import { Typography } from '@rmwc/typography';

const CardInfo = ({ image = {}, options = {} }) => {
    const { chatData: data, dispatchChatData, internalSendMessage } = useContext(ChatContext);
    const handleClick = async (buttonText, postback) => {
      const now = Math.round(Date.now() / 1000);
      dispatchChatData({
        type: 'add',
        payload: [{
          type: 'text',
          text: buttonText,
          from: 0,
          date: now,
        }],
      });
      await internalSendMessage({
        dialogId: data.config.dialogId,
        provider: data.config.provider,
        text: postback,
      });
    };

    return (
      <Card style={{ minWidth: '12.5rem', maxWidth: '100%' }}>
        <CardMedia
          sixteenByNine
          style={{
            backgroundImage: `url(${image.source})`,
          }}
        />
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <Typography use={'headline6'} tag={'h2'}>
            {image.title}
          </Typography>
          <Typography
            use={'body1'}
            tag={'div'}
            theme={'textSecondaryOnBackground'}
          >
            {image.description}
          </Typography>
        </div>
        <CardActions>
          <CardActionButtons>
            {(options || []).filter((it) => (it.value.input.text || '').length > 0)
                  .map((it, i) => {
                  const { label: buttonText, value } = it;
                  const { text: postback } = value.input;
                  // eslint-disable-next-line react/jsx-no-target-blank
                  return (
                    <CardActionButton
                      key={i}
                      label={buttonText}
                      className={'button-card'}
                      onClick={() => {
                      handleClick(buttonText, postback);
                    }}/>);
                })}
          </CardActionButtons>
        </CardActions>
      </Card>
    );
};

CardInfo.propTypes = {
    image: PropTypes.object,
    options: PropTypes.array,
  };

  export default CardInfo;
