/* eslint-disable no-console */
import ChatApi from '../api/chat-api';
import Utils from '../utils/chat-utils';
const today = new Date();

export default class HistoryMessage {
  static getHistoryMessage = async (infConversation, ally, channel) => {
    let arrayMessages = [];
    const date = Utils.getDateFormat(today);
    const { clientId, advisor } = infConversation;

    if (clientId && ally && channel && advisor === true) {
      arrayMessages = await ChatApi.getMessages({
        clientId,
        ally,
        channel,
        date,
      })
        .then((message) => {
          return message;
        })
        .catch((err) => console.log(err));
    }
    return arrayMessages;
  }
}
