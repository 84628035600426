import { api, escalateError, getResponseData } from './index';

export default class ChatApi {
  static getName(name) {
    return name.split(/("|')((?:\\\1|(?:(?!\1).))*)\1/)[2];
  }

  static buildMessageRequestBody({
    dialogId,
    message,
    provider,
    conversationId,
    conversationStart,
    clientId,
    ally,
    botName,
    integrationId,
    token,
    responseType,
    fileName,
    name,
  }) {
    const now = new Date();
    const botId = `bot-${clientId}-${botName || 'x'}`;
    const autoConversationId = `convo-${botId}-${conversationId || 'y'}`;
    const actualClientId = clientId || conversationId || autoConversationId;
    return {
      token,
      dialogId,
      workspaceId: dialogId,
      provider,
      message,
      ally: ally || 'bpsmart',
      channel: 'website',
      conversationId: conversationId || autoConversationId,
      conversationStart: conversationStart || now.toISOString(),
      conversationStartTime: conversationStart || now.toISOString(),
      conversationUpdateTime: now.toISOString(),
      clientId: `${actualClientId}-${botName || 'x'}`,
      userId: actualClientId,
      userid: actualClientId,
      clientName: name,
      clientEmail: `${actualClientId}@bpsmart.ai`,
      botId,
      integrationId,
      botName: botId,
      botAlias: botId,
      responseType,
      fileName,
    };
  }

  static getMessages({ clientId, ally, channel, date }) {
    return api.get(`chats/${clientId}?ally=${ally}&channel=${channel}&date=${date}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static sendMessageDirectly(data) {
    return api.post('chats', data).then(getResponseData)
      .catch(escalateError);
  }

  static sendMessageDialogFlow({
    dialogId,
    message,
    conversationId,
    conversationStart,
    clientId,
    ally,
    botName,
    token,
    responseType,
  }) {
    return this.sendMessageDirectly(
      this.buildMessageRequestBody({
        dialogId,
        message,
        provider: 'dialogflow',
        conversationId,
        conversationStart,
        clientId,
        ally,
        botName,
        token,
        responseType,
      })
    );
  }

  static initDialogFlow(token) {
    return api
      .post(
        'dialogflow/message/session',
        {},
        { headers: { Authorization: token } }
      )
      .then(getResponseData)
      .catch(escalateError);
  }

  static sendMessageLex({
    dialogId,
    message,
    clientId,
    ally,
    botName,
    token,
    conversationId,
  }) {
    return this.sendMessageDirectly(
      this.buildMessageRequestBody({
        dialogId,
        message,
        provider: 'lex',
        clientId,
        ally,
        botName,
        conversationId,
        token,
      })
    );
  }

  static sendMessageWatson({
                             dialogId,
                             message,
                             clientId,
                             integrationId,
                             ally,
                             botName,
                             token,
                             conversationId,
                             responseType,
                             fileName,
                             name,
                           }) {
    return this.sendMessageDirectly(
      this.buildMessageRequestBody({
        dialogId,
        message,
        provider: 'watson',
        clientId,
        ally,
        botName,
        conversationId,
        token,
        integrationId,
        responseType,
        fileName,
        name,
      })
    );
  }

  static sendMessageWatsonAdvisor({
                                    messageText,
                                    clientId,
                                    integrationId,
                                    channel,
                                    ally,
                                    advisor,
                                    adviserEmail,
                                    clientCampaignId,
                                    responseType,
                                    fileName,
                                    dataPerson,
                                  }) {
    return api
      .post('chats', {
        messageText,
        clientId,
        integrationId,
        dataPerson,
        channel,
        ally,
        adviserEmail,
        clientCampaignId,
        advisor,
        responseType,
        fileName,
      })
      .then(getResponseData)
      .catch(escalateError);
  }

  static convertToText(bufferAudio, token) {
    return api
      .post('chats/recognize', { buffer: bufferAudio, token })
      .then((resp) => getResponseData(resp.data))
      .catch(escalateError);
  }

  static postUploadFile(fileBuffer, dataClient) {
    return api.post('chats/uploadFile', {
      dataClient,
      fileBuffer,
    });
  };
}
