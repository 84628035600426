import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../chat-provider';
import TitleBar from '../title-bar/title-bar';
import ButtonsList from './ButtonsList';
import ImageView from './ImageView';
import CardInfo from './CardInfo';
import './messages.css';
import AudioView from './AudioView';
import formatText from '../utils/textFormater';

const sleep = (ms = 1) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const Messages = ({
  ally, includeTitleBar = true, titleBarLogo = null, titleBarContent = null
  , infConversation = null, firebase = {},
}) => {
  const { chatData: data, dispatchChatData } = useContext(ChatContext);
  const [typing, setTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const messages = (data.messages || []).flat();
  const newMessages = (data.new || []).flat();
  const messagesCount = messages.length;
  const processNewMessages = async () => {
    if (newMessages.length <= 0) return;
    for (let i = 0; i < newMessages.length; i++) {
      const it = { ...newMessages[i] };
      setTyping(true);
      await sleep(it.time || 500);
      if (it.type !== 'pause') {
        setTyping(false);
        dispatchChatData({ type: 'add', payload: [{ ...it, time: 0 }] });
        await sleep(100);
      }
    }
    dispatchChatData({ type: 'clear-new' });
  };

  const scrollToBottom = () => {
    try {
      const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
      if (lastMessage) {
        setTimeout(() => {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }, lastMessage.from === 1 ? 50 : 1);
      }
    } catch (e) {
    }
  };

  const formatAMPM = (date, from) => {
    const day = date.getDate();
    let origin = '';
    const month = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov',
      'Dic'];
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    if (infConversation.advisor) {
      origin = from === 0 ? 'Tú' : infConversation.clientName;
    } else {
      origin = from === 0 ? 'Tú' : ally.replace(/^\w/, (c) => c.toUpperCase());
    }
    return `${origin} | ${day} ${month[date.getMonth()]} - ${hours}:${minutes} ${ampm}`;
  };

  useEffect(scrollToBottom, [data.messages]);

  useMemo(processNewMessages, [data.new]);

  const renderMessage = (message) => {
    const {
      type = '',
      text = '',
      from,
      source,
      title = '',
      description = '',
      suggestions = [],
      date,
    } = message;
    switch (type) {
      case 'suggestion':
        return (<>
          {suggestions.map((it, i) => (<p key={i}>- {it.label || '…'}</p>))}
        </>);
      case 'image':
        return (<>
          <ImageView source={text}
                     title={title}
                     firebase={firebase}
                     description={description}
                     storage={source}
                     date={formatAMPM(new Date(), from)} />
        </>);
      case 'image-history':
        return (<>
          <img src={source} alt={title} />
          <p>{title} ({description})</p>
          <small>{formatAMPM(new Date(date * 1000), from)}</small>
        </>);
      case 'file':
        return (<ImageView source={text}
                           file={true}
                           firebase={firebase}
                           title={title}
                           description={description}
                           date={formatAMPM(new Date(), from)} />);
      case 'history':
        if (Object.keys(text).includes('text')) {
          const historyText = (text.text || []);
          if (historyText.length > 0) return (<p>{formatText(historyText)}</p>);
          return (<></>);
        }
        if (text.length > 0) {
          return (
            <><p dangerouslySetInnerHTML={formatText(text)} />
              <br /><small>{formatAMPM(new Date(date * 1000), from)}</small></>
          );
        }
        return (<></>);
      case 'text':
        if (Object.keys(text).includes('text')) {
          const actualText = (text.text || []);
          if (actualText.length > 0) return (<p>{formatText(actualText)}</p>);
          return (<></>);
        }
        if (text.length > 0) {
          return (
            <><p dangerouslySetInnerHTML={formatText(text)} />
              <br /><small>{formatAMPM(new Date(date * 1000), from)}</small></>
          );
        }
        return (<></>);
      case 'audio':
        return (<>
          <AudioView source={text} description={description} firebase={firebase} />
          <small>{formatAMPM(new Date(), from)}</small>
        </>);
      case 'card': {
        const { image } = message;
        const { options = [] } = message.options;
        return (<>
          <CardInfo image={image} options={options} />
          <br /><small>{formatAMPM(new Date(), from)}</small>
        </>);
      }
      case 'option': {
        const { options, title } = message;
        return (<>
          <ButtonsList options={options} title={title} />
          <br /><small>{formatAMPM(new Date(), from)}</small>
        </>);
      }
      case 'pause':
        return (<></>);
      default:
        // eslint-disable-next-line no-console
        console.error('Unsupported message ↓');
        // eslint-disable-next-line no-console
        console.table(message);
        return (<>...</>);
    }
  };

  const getMessageClassName = (message, index) => {
    const classes = ['message'];
    classes.push(message.from === 0 ? 'right' : 'left');
    classes.push(message.error ? 'error' : '');
    // eslint-disable-next-line no-nested-ternary
    classes.push(index === 0 ? 'first' : index >= messagesCount - 1 ? 'last' : '');
    return classes.filter((it) => it.length > 0)
      .join(' ')
      .trim();
  };

  return (<React.Fragment>
    {includeTitleBar && (<TitleBar showTyping={typing}
                                   titleBarLogo={titleBarLogo}
                                   titleBarContent={titleBarContent} />)}
    <div className={'messages'}>
      {messages.map((it, i) => {
        if (i === 0 && infConversation.initMessagge) return ('');
        else {
          return (<div className={getMessageClassName(it, i)} key={i}>
            <p>{renderMessage(it)}</p>
          </div>);
        };
      })}
      <div ref={messagesEndRef} />
    </div>
  </React.Fragment>);
};

Messages.propTypes = {
  ally: PropTypes.string,
  includeTitleBar: PropTypes.bool,
  titleBarLogo: PropTypes.any,
  firebase: PropTypes.object,
  infConversation: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
  titleBarContent: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};

export default Messages;
