import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from '../chat-provider';
import ChatUtils from '../utils/chat-utils';
import ChatApi from '../api/chat-api';
import './uploadfile.css';
import { uuid } from 'uuidv4';

const UploadFile = ({
  disabled = false, dialogId = '', provider = '',
  ally = '', token = '', infConversation = {},
}) => {
  const { dispatchChatData, internalSendMessage } = useContext(ChatContext);
  const [fileName, setFileName] = useState('');
  const [fileBuffer, setFileBuffer] = useState('');
  const [fileURL, setFileURL] = useState(undefined);
  const amountBytes = 5000000; // limited 5MB

  useEffect(() => {
    if (fileURL && fileName) {
      const typeFile = fileName.substring(fileName.length - 4);
      const { channel, clientId } = infConversation;
      const dataClient = { fileName, typeFile, ally, channel, clientId };
      ChatApi.postUploadFile(fileBuffer, dataClient)
        .then((resp) => {
          renderFileType(resp.data.data);
        });
    }
  }, [fileURL]);

  const onLoadAudio = async (file) => {
    if (file !== undefined) {
      if (file.type.split('/')[0] === 'audio' || file.type.split('/')[0] === 'video') {
        const reader = new FileReader();
        reader.onload = (event) => {
          const urlBase64 = event.target.result;
          toDataURL(urlBase64);
        };
        reader.readAsDataURL(file);
      } else {
        onChangeFile(file);
      }
    }
  };

  const toDataURL = async (urlBase64) => {
    const response = await fetch(urlBase64);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    renderAudio(blob, blobURL);
  };

  const renderAudio = async (blob, blobURL) => {
    const bufferAudio = Buffer.from(new Uint8Array(await blob.arrayBuffer()));
    dispatchChatData({
      type: 'add',
      payload: [{
        type: 'audio',
        buffer: blobURL,
        from: 0,
      }],
    });
    await ChatApi.convertToText(bufferAudio, token)
      .then(async (result) => {
        await internalSendMessage({ provider, dialogId, text: result.data || '' });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

const renderFileType = async (file) => {
    if (file) {
      const { type, url } = file;
      const fileExtension = ChatUtils.extensionFile(type.toLowerCase());
      const { channel, clientId } = infConversation;

      const sourceFile = `${ally}/chat/${channel}/${clientId
        ? `${clientId}/${fileName.trim()}`
        : `${fileName.trim()}`}`;
      await internalSendMessage({
        provider,
        dialogId,
        text: sourceFile || '',
        typeFile: fileExtension,
        description: fileName.trim(),
      });

      dispatchChatData({
        type: 'add',
        payload: [{
          type: fileExtension, // image , file, text
          text: sourceFile,
          audio: url[0],
          audioURL: url[0],
          from: 0,
          description: fileName.trim(),
          date: Math.round(Date.now() / 1000),
        }],
      });
      setFileURL('');
      setFileName('');
      setFileBuffer(undefined);
    }
  };

  const onChangeFile = (evt) => {
    if (evt !== undefined) {
      if (evt.type === 'audio' || evt.type === 'video') {
        onLoadAudio(evt);
      } else {
        if (evt.name.length > 0 && evt.size < amountBytes) readImage(evt);
        if (evt.size > amountBytes) {
          setFileName('');
          setFileBuffer('');
          setFileURL();
        }
      }
    }
  };

  const onInputClick = (e) => {
    if (e) {
      e.target.value = '';
    }
  };

  const readImage = async (file) => {
    const urlImage = await readURL(file);
    const buffer = await readBuffer(file);
    const uint8View = new Uint8Array(buffer);
    setFileName(`${uuid()}.${file.name.split('.').at(-1)}`);
    setFileBuffer(Buffer.from(uint8View));
    setFileURL(urlImage);
  };

  const readURL = async (file) => new Promise(
    (resolve, reject) => {
      const readerURL = new FileReader();
      readerURL.onload = () => {
        resolve(readerURL.result);
      };
      readerURL.onerror = reject;
      readerURL.readAsDataURL(file);
    }
  );

  const readBuffer = async (file) => new Promise(
    (resolve, reject) => {
      const readerBuffer = new FileReader();
      readerBuffer.onload = () => {
        resolve(readerBuffer.result);
      };
      readerBuffer.onerror = reject;
      readerBuffer.readAsArrayBuffer(file);
    }
  );

  return (
    <>
      <div id={'container'}>
        <input type={'checkbox'} id={'btn'} disabled={disabled} />
        <div className={'buttons'}>
          <label htmlFor={'btn'} className={'icon'}>
            <i className={'mdi mdi-24px mdi-paperclip'} />
          </label>
          <a><label htmlFor={'file-upload'} className={'custom-file-upload'}>
            <i className={'mdi mdi-24px mdi-file'} />
          </label>
            <input id={'file-upload'} type={'file'} accept={'.pdf,.doc,.docx,.csv,.txt, .xlsx'}
                   onChange={(e) => onChangeFile(e.target.files[0])} onClick={onInputClick}/>
          </a>
          <a><label htmlFor={'image-upload'} className={'custom-file-upload'}>
            <i className={'mdi mdi-24px mdi-image'} onClick={onInputClick} />
          </label>
            <input id={'image-upload'} type={'file'} accept={'image/*'}
                   onChange={(e) => onChangeFile(e.target.files[0])}
                   onClick={onInputClick}/>
          </a>
          <a><label htmlFor={'audio-upload'} className={'custom-file-upload'}>
            <i className={'mdi mdi-24px mdi-headphones'} />
          </label>
            <input id={'audio-upload'} type={'file'} accept={'audio/*'}
                   onChange={(e) => onChangeFile(e.target.files[0])}
                   onClick={onInputClick} />
          </a>
        </div>
      </div>
    </>
  );
};
UploadFile.propTypes = {
  disabled: PropTypes.bool,
  dialogId: PropTypes.string,
  provider: PropTypes.string,
  ally: PropTypes.string,
  token: PropTypes.string,
  infConversation: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};
export default UploadFile;
