export default class ChatUtils {
  static detectBrowser = () => {
    try {
      return (function (agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('edg') > -1:
            return 'chromium-based-edge';
          case agent.indexOf('opr') > -1 && !!window.opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
      })(window.navigator.userAgent.toLowerCase());
    } catch (e) {
      return 'unknown';
    }
  };

  static randomBetween = (min, max) => {
    return Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  };

  static getRandomIP = () => {
    return `${this.randomBetween(0, 255)}-${this.randomBetween(0, 255)}-`
      + `${this.randomBetween(0, 255)}-${this.randomBetween(0, 255)}-r`;
  };

  static chatDataReducer = (data, action) => {
    switch (action.type) {
      case 'convo':
        return {
          ...data,
          conversationId: action.conversationId,
          conversationStart: action.conversationStart,
        };
      case 'new':
        return { ...data, new: [action.payload] };
      case 'clear-new':
        return { ...data, new: [] };
      case 'add':
        return { ...data, messages: [...data.messages, action.payload] };
      default:
        return { ...data };
    }
  };

  static setupDefaultConversationId = (clientId, botName) => {
    const now = new Date();
    const preConvoId = `convo-${clientId}-${botName || 'x'}`;
    const cleanDate = now.toISOString().replace(/Z/gi, '')
      .replace(/[^0-9]/gi, '-')
      .substring(0, 10);
    return (`${preConvoId}-${cleanDate}`);
  };

  static typeMessages = (responseText, defaultTypingTime) => {
    const responses = [];
    if (Array.isArray(responseText)) {
      if (responseText.filter((resp) => resp.response_type === 'image').length > 0) {
        responseText.forEach((it, index) => {
          const { title = '' } = it;
          if (index >= 1) {
            if (it.response_type === 'option'
              && responseText[index - 1].response_type === 'image') {
              responses.push({
                image: responseText[index - 1],
                options: it,
                type: 'card',
                from: 1,
                time: (it.time || defaultTypingTime),
              });
            }
          }
          if (!(it.response_type === 'image'
            && responseText[index + 1].response_type === 'option')) {
            if (it.response_type === 'text' || it.response_type === 'image'
              || (it.response_type === 'option' && /menu/.test(title)) || it.response_type === 'pause') {
              responses.push({
                ...it,
                type: it.response_type || it.message,
                from: 1,
                time: (it.time || defaultTypingTime),
              });
            }
          }
        });
      } else {
        responseText.forEach((textMessages) => {
          if (textMessages.response_type === 'option'
            || textMessages.response_type === 'text' || textMessages.response_type === 'pause') {
            responses.push({
              ...textMessages,
              type: textMessages.response_type || textMessages.message,
              from: 1,
              time: (textMessages.time || defaultTypingTime),
            });
          }
        });
      }
    } else {
      responses.push({
        type: 'text',
        text: responseText,
        from: 1,
        time: (responseText.time || defaultTypingTime),
      });
    }
    return responses;
  };

  static getDateFormat = (objectDate) => {
    const zero = '0';
    const month = (objectDate.getMonth() + 1) < 10
      ? zero.concat(objectDate.getMonth() + 1)
      : objectDate.getMonth() + 1;
    const day = objectDate.getDate() < 10
      ? zero.concat(objectDate.getDate())
      : objectDate.getDate();
    const date = `${objectDate.getFullYear()}-${month}-${day}`;
    return date;
  };

  static extensionFile = (typeFile) => {
    const extensions = {
      audio: 'audio',
      jpg: 'image',
      png: 'image',
      svg: 'image',
      jpeg: 'image',
      doc: 'file',
      docx: 'file',
      pdf: 'file',
      csv: 'file',
      txt: 'file',
      xlsx: 'file',
      pptx: 'file',
      mp3: 'audio',
      wav: 'audio',
      flac: 'audio',
      wma: 'audio',
      aac: 'audio',
    };
    return extensions[typeFile] || undefined;
  };
}
