import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@rmwc/card';
import { ChatContext } from '../chat-provider';
import Messages from '../messages/messages';
import Recorder from '../recorder/recorder';
import UploadFile from '../upload-file/UploadFile';
import ChatUtils from '../utils/chat-utils';
import ChatApi from '../api/chat-api';
import './chat.css';
import { uuid } from 'uuidv4';

const Chat = ({
  ally, config, includeTitleBar = true, titleBarLogo = null,
  titleBarContent = null, token = null, infConversation = null,
  firebase = {},
}) => {
  const { dispatchChatData: dispatchData, internalSendMessage, userIP } = useContext(ChatContext);
  const [dateMessage, setDateMessage] = useState(null);
  const [newMessage, setNewMessage] = useState();
  const initMessage = (infConversation.initMessagge);
  const { provider, dialogId } = config || {};
  const shouldBeDisabled = (dialogId || '').length <= 0 || (provider || '').length <= 0;

  const [fileName, setFileName] = useState('');
  const [fileBuffer, setFileBuffer] = useState('');
  const [fileURL, setFileURL] = useState(undefined);
  const amountBytes = 5000000; // limited 5MB

  useEffect(() => {
    if (fileURL && fileName) {
      const typeFile = fileName.substring(fileName.length - 4);
      const { channel, clientId } = infConversation;
      const dataClient = { fileName, typeFile, ally, channel, clientId };
      ChatApi.postUploadFile(fileBuffer, dataClient)
        .then((resp) => {
          renderFileType(resp.data.data);
        });
    }
  }, [fileURL]);

  const renderFileType = async (file) => {
    const fileExtension = ChatUtils.extensionFile(file.type);
    const { channel, clientId } = infConversation;
    const sourceFile = `${ally}/chat/${channel}/${clientId
      ? `${clientId}/${fileName.trim()}`
      : `${fileName.trim()}`}`;
    await internalSendMessage({
      provider,
      dialogId,
      text: sourceFile || '',
      typeFile: fileExtension,
      description: fileName.trim(),
    });
    dispatchData({
      type: 'add',
      payload: [{
        type: fileExtension, // image , file, text
        text: sourceFile,
        from: 0,
        description: fileName.trim(),
        date: Math.round(Date.now() / 1000),
      }],
    });
  };

  const onLoadAudio = async (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const urlBase64 = event.target.result;
      toDataURL(urlBase64);
    };
    reader.readAsDataURL(file);
  };

  const toDataURL = async (urlBase64) => {
    const response = await fetch(urlBase64);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    renderAudio(blob, blobURL);
  };

  const renderAudio = async (blob, blobURL) => {
    const bufferAudio = Buffer.from(new Uint8Array(await blob.arrayBuffer()));
    dispatchData({
      type: 'add',
      payload: [{
        type: 'audio',
        buffer: blobURL,
        from: 0,
      }],
    });
    await ChatApi.convertToText(bufferAudio, token)
      .then(async (result) => {
        await internalSendMessage({ provider, dialogId, text: result.data || '' });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  const onChangeFile = (evt) => {
    if (evt !== undefined) {
      if (evt.type.split('/')[0] === 'audio') {
        onLoadAudio(evt);
      } else {
        if (evt.name.length > 0 && evt.size < amountBytes) readImage(evt);
        if (evt.size > amountBytes) {
          setFileName('');
          setFileBuffer('');
          setFileURL();
        }
      }
    }
  };

  const readImage = async (file) => {
    const urlImage = await readURL(file);
    const buffer = await readBuffer(file);
    const uint8View = new Uint8Array(buffer);
    setFileName(`${uuid()}.${file.name.split('.').at(-1)}`);
    setFileBuffer(Buffer.from(uint8View));
    setFileURL(urlImage);
  };

  const readURL = async (file) => new Promise(
    (resolve, reject) => {
      const readerURL = new FileReader();
      readerURL.onload = () => {
        resolve(readerURL.result);
      };
      readerURL.onerror = reject;
      readerURL.readAsDataURL(file);
    }
  );

  const readBuffer = async (file) => new Promise(
    (resolve, reject) => {
      const readerBuffer = new FileReader();
      readerBuffer.onload = () => {
        resolve(readerBuffer.result);
      };
      readerBuffer.onerror = reject;
      readerBuffer.readAsArrayBuffer(file);
    }
  );

  const sendMessage = async () => {
    if (!newMessage || newMessage.length <= 0) return;
    const copy = { text: newMessage };
    setNewMessage('');
    dispatchData({
      type: 'add',
      payload: [{
        type: 'text',
        text: copy.text,
        from: 0,
        date: dateMessage,
      }],
    });
    await internalSendMessage({ dialogId, provider, text: copy.text });
  };

  const onKeyPressed = (e) => {
    const now = Math.round(Date.now() / 1000);

    setDateMessage(now);
    if (!(e.which === 13 && e.shiftKey) && (e.key.toString().toLowerCase() === 'enter')) {
      e.preventDefault();
      sendMessage()
        .catch(() => {
        });
    };
  };

  useEffect(() => {
    if (initMessage !== undefined && userIP.indexOf('.') !== -1) {
      const copy = { text: initMessage };
      dispatchData({
        type: 'add',
        payload: [{
          type: 'text',
          text: copy.text,
          from: 0,
          date: dateMessage,
        }],
      });
      internalSendMessage({ dialogId, provider, text: copy.text });
    }
  }, [userIP]);

  const uploadFile = () => {
    if (shouldBeDisabled) return (<></>);
    return (<UploadFile isabled={shouldBeDisabled} ally={ally} infConversation={infConversation}
                        dialogId={dialogId} provider={provider} token={token} />);
  };

  const renderRecorder = () => {
    if (shouldBeDisabled) return (<></>);
    return (<Recorder
      disabled={shouldBeDisabled}
      dialogId={dialogId} provider={provider} token={token}
      infConversation={infConversation}
      ally={ally} />);
  };

  const renderEmojiPicker = () => {
    return (<div className={'style-emoji'}>
      <i className={'mdi mdi-24px mdi-emoticon-excited'} />
    </div>);
  };
  return (<Card outlined className={'chat-box'}>
    <Messages
      ally={ally}
      includeTitleBar={includeTitleBar}
      titleBarLogo={titleBarLogo}
      titleBarContent={titleBarContent}
      firebase={firebase}
      infConversation={infConversation} />
    <div className={'chat-input'}>
      {uploadFile()}
      {renderEmojiPicker()}
      <textarea placeholder={'Hola'} type={'text'}
                className={'style-input'}
                value={newMessage} disabled={shouldBeDisabled}
                onKeyDown={onKeyPressed}
                onChange={(e) => setNewMessage(e.target.value)}
                onPaste={(e) => onChangeFile(e.clipboardData.files[0])} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {renderRecorder()}
        <button className={'style-send-button'} onClick={sendMessage} disabled={shouldBeDisabled}
                id={'button-chat'}>
          <span className={'mdi mdi-24px mdi-send'} />
        </button>
      </div>
    </div>
  </Card>);
};

Chat.propTypes = {
  ally: PropTypes.string,
  config: PropTypes.object,
  includeTitleBar: PropTypes.bool,
  titleBarLogo: PropTypes.any,
  token: PropTypes.string,
  firebase: PropTypes.object,
  infConversation: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
  titleBarContent: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};

export default Chat;
