const formatText = (text) => {
    let newDesc = text.replace(/\n/gmu, '<br>');
    const matchBold = text.match(/\*([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)\*/gmu);
    const matchLink = text.match(/(http[s]?:\/\/)?[\w\\-]+(\.[\w\\-]+)+\S+/gmu);
    const matchItalic = text.match(/~([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)~/gmu);
    const matchUnderScore = text.match(/_([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)_/gmu);
    const matchScript = text.match(/--([\w\s\p{L}!"#$%&/()¡?¿+[\]]+)--/gmu);
    const matchTittle = text.match(/#([\w\s\p{L}!"#$%&()¡?¿+[\]]+)#/gmu);
    if (matchBold) {
      matchBold.forEach((element) => {
        newDesc = newDesc.replace(element,
          `<strong>${element.substring(1, element.length - 1)}</strong>`);
      });
    }
    if (matchItalic) {
      matchItalic.forEach((element) => {
        newDesc = newDesc.replace(element, `<i>${element.substring(1, element.length - 1)}</i>`);
      });
    }
    if (matchLink) {
      matchLink.forEach((element) => {
        let link = element;
        if (!element.match(/https:\/\//)) {
          link = `https://${element}`;
        }
        newDesc = newDesc.replace(element,
          `<a href="${link}" style="word-break: break-word; color: aliceblue;" target="_blank">
            ${element}</a>`);
      });
    }
    if (matchUnderScore) {
      matchUnderScore.forEach((element) => {
        newDesc = newDesc.replace(element,
          `<span style="text-decoration: underline">
            ${element.substring(1, element.length - 1)}
          </span>`);
      });
    }
    if (matchTittle) {
      matchTittle.forEach((element) => {
        newDesc = newDesc.replace(element,
          `<h2 style="font-weight: 500;">
            ${element.substring(1, element.length - 1)}
          </h2>`);
      });
    }
    if (matchScript) {
      matchScript.forEach((element) => {
        newDesc = newDesc.replace(element,
          `<span style="text-decoration: line-through;">
            ${element.substring(2, element.length - 2)}
          </span>`);
      });
    }
    return ({ __html: newDesc });
  };

export default formatText;
