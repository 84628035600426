import React from 'react';
import PropTypes from 'prop-types';

import '@rmwc/card/styles';
import '@rmwc/tooltip/styles';
import '@rmwc/button/styles';
import '@rmwc/textfield/styles';
import '@mdi/font/css/materialdesignicons.min.css';

import ChatProvider from './chat-provider';
import Chat from './chat/chat';
import './index.css';

const BPSChat = ({
  config,
  ally = 'bpsmart',
  botName = 'default',
  token = '',
  includeTitleBar = true,
  titleBarLogo = null,
  titleBarContent = null,
  firebase = {},
  infConversation,
}) => {
  return (
    <ChatProvider ally={ally} botName={botName}
                  token={token} infConversation={infConversation}>
      <Chat
        ally={ally}
        config={config}
        includeTitleBar={includeTitleBar}
        titleBarLogo={titleBarLogo}
        titleBarContent={titleBarContent}
        firebase = {firebase}
        infConversation={infConversation}
        token={token}
      />
    </ChatProvider>
  );
};

// Add value to differentiate the environment th build is requerid
BPSChat.propTypes = {
  config: PropTypes.object.isRequired,
  ally: PropTypes.string.isRequired,
  botName: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  includeTitleBar: PropTypes.bool,
  titleBarLogo: PropTypes.any,
  firebase: PropTypes.object,
  titleBarContent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  infConversation: PropTypes.object,
};

export default BPSChat;
