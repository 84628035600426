import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactMic } from 'react-mic';
import { Tooltip } from '@rmwc/tooltip';
import { ChatContext } from '../chat-provider';
import ChatApi from '../api/chat-api';
import './recorder.css';
import ChatUtils from '../utils/chat-utils';
import { uuid } from 'uuidv4';

const Recorder = ({
  disabled = true, dialogId = '', provider = '',
  ally = '',
  token = '', infConversation = {},
}) => {
  const { dispatchChatData: dispatchData, internalSendMessage } = useContext(ChatContext);
  const [audioName, setAudioName] = useState(undefined);
  const [audioBuffer, setAudioBuffer] = useState(undefined);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    if (!isRecording) {
      if (audioName && audioBuffer) {
        const { channel, clientId } = infConversation;
        const dataClient = { fileName: audioName, typeFile: 'audio', channel, clientId, ally };

        ChatApi.postUploadFile(audioBuffer, dataClient)
          .then((res) => {
            renderAudio(res.data.data);
        })
          // eslint-disable-next-line no-console
          .catch((err) => console.log((err.message || err.error)));
      }
    }
  }, [audioName, audioBuffer]);

  const renderAudio = async (file) => {
    if (file) {
      const { type, url } = file;
      const fileExtension = ChatUtils.extensionFile(type.toLowerCase());
      const { channel, clientId } = infConversation;

      const sourceFile = `${ally}/chat/${channel}/${clientId
        ? `${clientId}/${audioName.trim()}`
        : `${audioName.trim()}`}`;

      dispatchData({
        type: 'add',
        payload: [{
          type: fileExtension, // image , file, text
          text: sourceFile,
          audio: url[0],
          audioURL: url[0],
          from: 0,
          description: audioName.trim(),
          date: Math.round(Date.now() / 1000),
        }],
      });

      await internalSendMessage({
        provider,
        dialogId,
        text: sourceFile || '',
        typeFile: 'voice',
        description: audioName.trim(),
      });
    }
    setAudioName(undefined);
    setAudioBuffer(undefined);
  };

  const startRecording = () => {
    if (!dialogId || dialogId.length <= 0) return;
    if (!provider || provider.length <= 0) return;
    setIsRecording(true);
  };

  const stopRecording = () => setIsRecording(false);

  const onStop = async (recordedBlob) => {
    const { advisor, channel } = infConversation;
    const bufferAudio = Buffer.from(new Uint8Array(await recordedBlob.blob.arrayBuffer()));
    if (advisor && advisor === true) {
      setAudioName(`VOICE_AUDIO-${uuid()}.${channel === 'whatsApp' ? 'mp3' : 'ogg'}`);
      setAudioBuffer(bufferAudio);
    } else {
      dispatchData({
        type: 'add',
        payload: [{
          type: 'audio',
          buffer: recordedBlob.blobURL,
          from: 0,
          source: '',
        }],
      });
      await ChatApi.convertToText(bufferAudio, token)
        .then(async (result) => {
          await internalSendMessage({ provider, dialogId, text: result.data || '' });
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  };

  const renderMic = () => {
    if (!window) return (<></>);
    const context = window.AudioContext || window.webkitAudioContext;
    if (!context) return (<></>);
    return (<ReactMic
      record={isRecording}
      onStop={onStop}
      height={0}
      width={0}
      channelCount={1}
      sampleRate={44100}
      mimeType={'audio/ogg'} />);
  };

  return (<div>
    {renderMic()}
    <Tooltip content={'Recording'} activateOn={'focus'} showArrow>
      <button className={'recording-button'}
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
              disabled={disabled}>
        {!isRecording
          ? <span className={'mdi mdi-24px mdi-microphone'} />
          : <span className={'mdi mdi-24px mdi-microphone-outline'} />}
      </button>
    </Tooltip>
  </div>);
};

Recorder.propTypes = {
  ally: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dialogId: PropTypes.string,
  provider: PropTypes.string,
  token: PropTypes.string,
  infConversation: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
};

export default Recorder;
